<template>
  <div class="pb-2 pt-1">
    <b-button
      class="bg-success"
      @click="modalActive=true"
    >
      {{ $t('Add') }}
    </b-button>
    <div class="mt-3">
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        v-model="modalActive"
        title="Add Test"
        hide-footer
        size="lg"
      >
        <b-row>
          <b-col md="6">
            <form
              ref="form"
              @submit.stop.prevent="handleSubmit"
            >
              <b-form-group
                label="Paragraph UZ"
                label-for="name-input"
                invalid-feedback="Name is required"
              >
                <b-form-input
                  id="name-input"
                  name="paragraph_uz"
                  v-model="paragraph.paragraph_uz"
                  required
                  size="lg"
                />
              </b-form-group>
            </form>
            <form
              ref="form"
              @submit.stop.prevent="handleSubmit"
            >
              <b-form-group
                label="Paragraph RU"
                label-for="name-input"
                invalid-feedback="Name is required"
              >
                <b-form-input
                  id="name-input"
                  name="paragraph_ru"
                  v-model="paragraph.paragraph_ru"
                  required
                  size="lg"
                />
              </b-form-group>
              <b-form-group
                label="Paragraph EN"
                label-for="name-input"
                invalid-feedback="Name is required"
              >
                <b-form-input
                  id="name-input"
                  name="paragraph_en"
                  v-model="paragraph.paragraph_en"
                  required
                  size="lg"
                />
              </b-form-group>
            </form>
          </b-col>
          <b-col md="6">
            <h6>Action</h6>
            <b-form-group
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                v-model="paragraph.cur_status_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="1"
              >
                New
              </b-form-radio>
              <b-form-radio
                v-model="paragraph.cur_status_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="2"
              >
                In progress
              </b-form-radio>
              <b-form-radio
                v-model="paragraph.cur_status_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="3"
              >
                Completed
              </b-form-radio>
            </b-form-group>
            <div class="mt-2">
              <b-form-group>
                <b-form-file
                  id="file-default"
                  v-model="paragraph.file_path"
                  value="file"
                />
              </b-form-group>
              <h5>Download the file</h5>
              <h6>File format: (doc, pdf, exc etc.)</h6>
            </div>
            <div class="pt-2">
              <b-form-datepicker
                v-model="paragraph.notification_date"
                name="notification_date"
                :min="min"
                :max="max"
                locale="en"
              />
              <b-form-timepicker
                  id="timepicker-placeholder"
                  name="notification_time"
                  v-model="paragraph.notification_time"
                  placeholder="Choose a time"
                  locale="en"
                  class="mt-2"
              />
            </div>
          </b-col>
        </b-row>
        <h4 class="mt-1">
          Users
        </h4>
        <b-row>
          <b-form-group
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio
              v-model="isMno"
              :aria-describedby="ariaDescribedby"
              name="radio"
              :value="true"
            >
              Ministerstvo narodnogo obrazavanie
            </b-form-radio>
            <b-form-radio
              v-model="isMno"
              :aria-describedby="ariaDescribedby"
              name="radio"
              :value="false"
              class="mt-1"
            >
              Drugoe vedomostbo
            </b-form-radio>
          </b-form-group>
        </b-row>
        <div
          v-if="isMno"
          class="mt-2"
        >
          <h5>Upravlenie</h5>
          <b-form-select
            v-model="paragraph.management_type_id"
            :options="management_type"
            text-field="name_uz"
            value-field="id"
            class="mt-2"
            name="management_type"
            @input="getManagementCategory"
          />
          <b-form-select
            v-model="paragraph.management_category_id"
            :options="management_category"
            text-field="name_ru"
            value-field="id"
            class="mt-2"
            name="management_category"
            @input="getManagementUser"
          />
          <b-form-group
            class="mt-2"
            label="Ministry Name"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="name-input"
              v-model="paragraph.fio"
              text-field="name_uz"
              size="lg"
              name="fio"
              required
            />
          </b-form-group>
        </div>
        <div v-else>
          <b-form-group
            label="Ministry Name"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="name-input"
              name="ministry_name"
              v-model="paragraph.ministry_name"
              required
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="mt-2">
          <b-container fluid>
            <div class="text-right mt-3">
              <b-button
                variant="danger"
                class="mr-2"
                @click="modalActive = false"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                variant="success"
                @click="handleSubmit"
              >
                Add Test
              </b-button>
            </div>
          </b-container>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  addParagraphs, getManagement, getManagementTypes,
} from '@/api/directories'

export default {
  name: 'InDocumentModal',
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 2)
    minDate.setDate(15)
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15)
    return {
      modalActive: false,
      paragraph: {
        paragraph_uz: '',
        paragraph_ru: '',
        paragraph_en: '',
        cur_status_id: '',
        file_path: '',
        notification_date: null,
        notification_time: null,
        executer_mno: '',
        management_section_id: '',
        management_id: '',
        ministry_name: '',
        fio: '',
        document_id: '',
      },
      management_type: [],
      management_category: [],
      min: minDate,
      max: maxDate,
      isMno: true,
    }
  },
  mounted() {
    getManagementTypes().then(res => {
      this.management_type = res.data.data
    })
  },
  methods: {
    handleSubmit() {
      if (this.paragraph.paragraph_uz
          && this.paragraph.paragraph_ru
          && this.paragraph.paragraph_en
          && this.paragraph.cur_status_id
          && this.paragraph.file_path
          && this.paragraph.notification_date
          && this.paragraph.notification_time
          && this.paragraph.executer_mno
          && this.paragraph.ministry_name
      ) {
        // eslint-disable-next-line no-unused-vars
        this.paragraph.document_id = this.$route.params.id;
        const form = new FormData();
        form.append('paragraph_uz',this.paragraph.paragraph_uz);
        form.append('paragraph_ru', this.paragraph.paragraph_ru);
        form.append('paragraph_en', this.paragraph.paragraph_en);
        form.append('cur_status_id', this.paragraph.cur_status_id);
        form.append('notification_date', this.paragraph.notification_date);
        form.append('notification_time', this.paragraph.notification_time);
        form.append('executer_mno', this.paragraph.executer_mno);
        form.append('ministry_name', this.paragraph.ministry_name)
        form.append('fio', this.paragraph.fio);
        form.append('management_section_id', this.paragraph.management_category_id);
        form.append('management_id', this.paragraph.management_type_id);
        form.append('document_id', this.paragraph.document_id);
        form.append('file',this.paragraph.file_path);
        addParagraphs(form).then(res => {
          this.modalActive = false
          this.$emit('paragraphAdded')
        })
      }
    },
    getManagementCategory() {
      getManagement({ type_id: this.paragraph.management_type_id, pageSize: 30 }).then(res => {
        this.management_category = res.data.data.data
      })
    },
    getManagementUser() {
      const index = this.management_category.findIndex(item => item.id === this.paragraph.management_category_id)
      const { user } = this.management_category[index]
      this.paragraph.fio = `${user.lastname} ${user.firstname}`
      this.paragraph.executer_mno = user.id
    },
  },
}
</script>

<style scoped>

</style>
