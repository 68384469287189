<template>
  <div>
    <b-row>
      <b-col md="9">
        <h4>InDocument</h4>
        <InDocumentModal @paragraphAdded="paragraphAdded" />
      </b-col>
      <div class="w-100">
        <b-table
          striped
          hover
          :items="paragraphs"
          :fields="fields"
          responsive=""
        >
          <template #cell(id)="data">
            <span class="text-md text-primary"> {{ (data.index +1) }}</span>
          </template>
          <template #cell(action)="data">
            <b-button
                size="sm"
                class="mr-1"
                variant="primary"
                @click="handlePush(data.item.id)"
            >
              <b-icon
                  icon="eye"
              />
            </b-button>
            <b-button
              size="sm"
              variant="warning"
              @click="handleEdit(data.item)"
            >
              <b-icon icon="pencil" />
            </b-button>
            <b-button
              class="ml-1"
              size="sm"
              variant="danger"
              @click="handleDelete(data.item.id)"
            >
              <b-icon icon="trash" />
            </b-button>
          </template>
        </b-table>
        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          v-model="modalActive"
          title="Edit Paragraph"
          hide-footer
          size="lg"
        >
        <EditInDoc
            :paragraphs="activeParagraph"
            @closeEditModal="closeModal"
            @editParagraph="paragraphEdited"
        />
        </b-modal>
      </div>
    </b-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import InDocumentModal from '@/views/loginpages/moderator/components/docaction/InDocmodal.vue'
import { deleteParagraph, getParagraphs, } from '@/api/directories'
import EditInDoc from '@/views/loginpages/moderator/components/docaction/EditInDoc'



export default {
  name: 'InDocument',
  components: { EditInDoc,   InDocumentModal, },
  data() {
    return {
      paragraphs: [],
      modalActive: false,
      activeParagraph: null,
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'document_id',
          label: 'Doc ID',
        },
        {
          key: 'paragraph',
          label: 'Paragraph',
        },
        {
          key: 'cur_status_id',
          label: 'Status ID',
        },
        {
          key: 'file_path',
          label: 'file',
        },
        {
          key: 'notification_date',
          label: 'notification_date',
        },
        {
          key: 'notification_time',
          label: 'notification_time',
        },
        {
          key: 'executer_mno',
          label: 'executor_mno',
        },
        {
          key: 'management_id',
          label: 'management_id',
        },
        {
          key: 'management_section_id',
          label: 'management_section_id',
        },
        {
          key: 'ministry_name',
          label: 'ministry_name',
        },
        {
          key: 'action',
          label: 'action',
        },
      ],
    }
  },
  created() {
    this.fetchParagraphs()
  },
  methods: {
    fetchParagraphs() {
      getParagraphs({ document_id: this.$route.params.id })
        .then(response => {
          this.paragraphs = response.data.data
        })
    },
    handleEdit(data) {
      // eslint-disable-next-line no-undef
      this.activeParagraph = _.cloneDeep(data)
      this.modalActive = true
    },
    paragraphAdded() {
      this.fetchParagraphs()
    },
    closeModal() {
      this.modalActive = false
    },
    paragraphEdited() {
      this.modalActive = false
      this.fetchParagraphs()
    },
    handleDelete(id) {
      if (id) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // eslint-disable-next-line no-unused-vars
            deleteParagraph({ id }).then(res => {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.fetchParagraphs()
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Your imaginary file is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      }
    },
    handlePush(id) {
      this.$router.push({ name: 'getdocument', params: { id:this.$route.params.id,paragraphId:id } })
    },
  },
}
</script>

<style scoped>

</style>
